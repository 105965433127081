import './contentPanel.scss';

export class ContentPanel {
  private collapseElement = document.createElement('button');

  constructor(private detailElement: HTMLElement) {
    detailElement.classList.add('contentPanel');
    this.visible = true;

    this.collapseElement.classList.add('contentPanel__collapseToggle');
    detailElement.appendChild(this.collapseElement);
    this.collapseElement.addEventListener('click', () => this.collapsed = !this.collapsed);
  }

  private _visible: boolean = false;
  get visible(): boolean {
    return this._visible;
  }

  set visible(visible: boolean) {
    this._visible = visible;
    this.detailElement.classList.toggle('visible', visible);

    if (visible === true && this.collapsed === true) {
      this.collapsed = false;
    }
  }

  private _collapsed: boolean = true;
  get collapsed(): boolean {
    return this._collapsed;
  }

  set collapsed(collapsed: boolean) {
    this._collapsed = collapsed;

    this.collapseElement.innerHTML = collapsed ? 'toon navigatie' : 'toon kaart';
    this.detailElement.classList.toggle('collapsed', collapsed);
  }
}
