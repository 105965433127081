import './detailPanel.scss';
import {Subject} from 'rxjs';
import {branches$} from '../../data/branches';

export class DetailPanel {
  public toListElement$ = new Subject();
  private contentElement = document.createElement('div');
  private toListElement = document.createElement('button');

  constructor(private detailElement: HTMLElement) {
    detailElement.classList.add('detailPanel');

    this.toListElement.classList.add('detailPanel__toList');
    this.toListElement.innerHTML = '⬸ terug naar overzicht';
    detailElement.appendChild(this.toListElement);
    this.toListElement.addEventListener('click', () => this.toListElement$.next());

    this.contentElement.classList.add('detailPanel__content');
    detailElement.appendChild(this.contentElement);
  }

  private _visible: boolean = false;
  get visible(): boolean {
    return this._visible;
  }

  set visible(visible: boolean) {
    this._visible = visible;
    this.detailElement.classList.toggle('visible', visible);
  }

  private _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detailElement.classList.toggle('isLoading', value);
  }

  showBranche(brancheId: string) {
    this.contentElement.innerHTML = '';
    this.isLoading = true;
    this.visible = true;

    branches$.subscribe(branches => {
      const branche = branches.find(({id}) => id === brancheId);

      if (branche) {
        this.contentElement.innerHTML = `
          <h1 class="listPanel__name">${branche.name}</h1>
          <address class="listPanel__address">${branche.address}</address>
          <p class="listPanel__numberOfVehicles"><strong>Aantal voertuigen</strong> ${branche.numberOfVehicles}</p>
          <p class="listPanel__description">${branche.description.replace('\n', '<br />')}</p>
      `;
      }

      this.isLoading = false;
    });
  }
}
