import './listPanel.scss';
import {Subject} from 'rxjs';
import {branches$} from '../../data/branches';

export class ListPanel {
  click$: Subject<string> = new Subject<string>();

  constructor(private listElement: HTMLElement) {
    listElement.classList.add('listPanel');
    this.visible = true;

    branches$.subscribe(branches => {
      listElement.innerHTML = '';
      branches.forEach((branche) => {
        listElement.innerHTML += `
        <button type="button" data-brancheid="${branche.id}" class="listPanel__button">
          <h3 class="listPanel__name">${branche.name}</h3>
          <address class="listPanel__address">${branche.address}</address>
        </button>`;
      });
    });

    listElement.addEventListener('click', (event) => {
      if (!event.target) {
        return;
      }

      // loop parent nodes from the target to the delegation node
      for (let target = event.target as HTMLButtonElement; target && target !== listElement; target = target.parentNode as HTMLButtonElement) {
        if (target.type === 'button' && target.hasAttribute('data-brancheid')) {
          this.click$.next(target.getAttribute('data-brancheid'));
          break;
        }
      }
    });
  }

  private _visible: boolean = false;

  get visible(): boolean {
    return this._visible;
  }

  set visible(visible: boolean) {
    this._visible = visible;
    this.listElement.classList.toggle('visible', visible);
  }
}
