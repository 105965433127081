import {ReplaySubject} from 'rxjs';

export const googleMapsLibrary$: ReplaySubject<typeof google.maps> = new ReplaySubject<typeof google.maps>(1);

declare global {
  interface Window {
    // add you custom properties and methods
    mapsInit: () => void
  }
}

const js = document.createElement('script');

js.type = 'text/javascript';
js.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDpxNiIPi0caSltBVk8ZQFq3NDq2XHEGaU&callback=mapsInit';

document.body.appendChild(js);

window.mapsInit = () => {
  googleMapsLibrary$.next(google.maps);
};
