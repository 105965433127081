import './mapsPanel.scss';
import {combineLatest, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {googleMapsStyles} from './style';
import {Branche} from '../../types/branche';
import {googleMapsLibrary$} from '../../util/googleMapsLibrary';
import {branches$} from '../../data/branches';

export class MapsPanel {
  public click$: Subject<string> = new Subject();
  private branches: (Branche & { marker: google.maps.Marker })[] = [];
  private map: google.maps.Map<HTMLElement>;

  constructor(mapsElement: HTMLElement) {
    mapsElement.classList.add('mapsPanel');

    combineLatest([
      googleMapsLibrary$.pipe(
        tap((googleMapsLibrary) => {
          // const myLatlng = new googleMapsLibrary.LatLng(52.1008, 5);
          const myLatlng = new googleMapsLibrary.LatLng(52.370216, 4.6995168);
          const mapOptions = {
            zoom: 10,
            center: myLatlng,
            mapTypeId: googleMapsLibrary.MapTypeId.ROADMAP,
            styles: googleMapsStyles,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false
          };

          this.map = new googleMapsLibrary.Map(mapsElement, mapOptions);
        })
      ),
      branches$
    ]).subscribe(([googleMapsLibrary, branches]) => {

      // create markers for
      this.branches = branches.map(branche => {
        const brancheLatLng = {
          lat: branche.lat,
          lng: branche.long
        };

        const marker = new googleMapsLibrary.Marker({
          position: brancheLatLng,
          map: this.map,
          title: branche.id
        });

        marker.addListener('click', () => this.click$.next(branche.id));

        return {
          ...branche,
          marker
        };
      });
    });
  }

  moveToBranche(brancheId: string) {
    const branche = this.branches.find(({id}) => id === brancheId);

    if (branche) {
      this.map.setZoom(14);
      this.map.panTo(branche.marker.getPosition());
    }
  }
}
