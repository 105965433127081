import './locator.scss';
import {ListPanel} from '../list/listPanel';
import {DetailPanel} from '../detail/detailPanel';
import {MapsPanel} from '../map/mapsPanel';
import {ContentPanel} from '../content/contentPanel';

export class Locator {
  private contentElement = document.createElement('div');
  private detailElement = document.createElement('div');
  private listElement = document.createElement('div');
  private mapElement = document.createElement('div');
  private contentPanel: ContentPanel;
  private mapsPanel: MapsPanel;
  private listPanel: ListPanel;
  private detailPanel: DetailPanel;

  constructor(private element: HTMLElement) {
    this.element.classList.add('locator');
    this.contentElement.appendChild(this.listElement);
    this.contentElement.appendChild(this.detailElement);
    this.element.appendChild(this.contentElement);
    this.element.appendChild(this.mapElement);

    this.contentPanel = new ContentPanel(this.contentElement);
    this.listPanel = new ListPanel(this.listElement);
    this.detailPanel = new DetailPanel(this.detailElement);
    this.mapsPanel = new MapsPanel(this.mapElement);

    this.detailPanel.toListElement$.subscribe(() => this.showList());

    this.listPanel.click$.subscribe((brancheId) => {
      this.selectBranche(brancheId);
    });

    this.mapsPanel.click$.subscribe((brancheId) => {
      this.selectBranche(brancheId);
    });

    this.showList();
  }

  showList(){
    this.contentPanel.visible = true;
    this.detailPanel.visible = false;
    this.listPanel.visible = true;
  }

  selectBranche(brancheId: string) {
    this.contentPanel.visible = true;
    this.listPanel.visible = false;
    this.mapsPanel.moveToBranche(brancheId);
    this.detailPanel.showBranche(brancheId);
  }
}
