import {ReplaySubject} from 'rxjs';
import {Branche} from '../types/branche';

export const branches$: ReplaySubject<Branche[]> = new ReplaySubject();


fetch(
  'https://api.keaz.software/v1/branches',
  {
    method: 'GET',
    headers: {
      'x-source-host': 'mister-green.keaz.software'
    }
  }
).then(async (response) => {
  const result: any[] = await response.json();

  const branches: Branche[] = result.map(branche => ({
    id: branche.id,
    lat: parseFloat(branche.lat),
    long: parseFloat(branche.long),
    address: branche.address,
    name: branche.name,
    description: branche.description,
    numberOfVehicles: branche.totals.vehicles
  }));

  branches$.next(branches);
});
